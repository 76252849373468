@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: transparent !important;
  height:100%;
}

body {
  height: 100%;
}

#__next {
  height: 100%;
}

@layer base {
  body {
    font-family: "Lato", system-ui, sans-serif;
  }
}
@media (min-width: 768px) {

  #progress-bar:before {
    filter: blur(7px);
  }
  
  #progress-bar {
    /* animation: animate 5s linear infinite; */
    background-image: linear-gradient(to top, #00ffe7, #008aff);
    height: 0%;
    position: fixed;
    right: 0;
    top: 0;
    width: 10px;
    z-index: 10;
  }
  
  #progress-bar:before,
  #progress-bar:after {
    background-image: linear-gradient(to top, #00ffe7, #008aff);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  #progress-bar:after {
    filter: blur(30px);
  }
  
  #scroll-path {
    background-color: rgba(255, 255, 255, 0.05);
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 10px;
    z-index: 9;
  }
  
  ::-webkit-scrollbar {
    width: 0;
  }
}

section {
  min-height: 100vh;
  padding: 100px;
  width: 100%;
}

section h2 {
  color: #666666;
  font-size: 4rem;
  margin-bottom: 20px;
}

section p {
  color: #666666;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@keyframes animate {
  0%,
  100% {
    filter: hue-rotate(0);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}


/* html,
body {
  padding: 0;
  margin: 0;
  font-family: monospace, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
} */

/**

a,p {
  color: inherit;
  text-decoration: none;
  font-family: monospace!important;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #121212;
}

*::-webkit-scrollbar
{
	width: 12px;
	background-color: #121212;
}

*::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #272727;
}

.column {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 1em;
  margin-bottom: 2em;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.single-column {
  margin-left: 20%;
  margin-right: 20%;
}

@media screen and (max-width: 992px) {
  .container {
    flex-direction: column;
  }

  .single-column {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.util-header {
  font-family: monospace;
  font-weight: 700;
  letter-spacing: 0.2rem;
  color: inherit;
  text-decoration: none;
}

.util-divider {
  margin-top: .5em;
  margin-bottom: 1em;
}

.error {
  margin-top: 1em;
  font-family: monospace;
  text-decoration: none;
}

.error a {
  text-decoration: underline;
}

.buttons {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
}

.buttons button {
  width: fit-content;
  margin-right: .5em;
}

.save {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
}

.save button {
  width: fit-content;
  margin-right: .5em;
}
**/
